<!-------------------------------------------------
description:帮助图标 ? 悬浮提示信息
/**
 *@author xiaosanye 
 *@from 平台收支账单 
 *@date 2023/2/8
*/
--------------------------------------------------->
<template>
  <div class="help-with-icon" v-if="isAskShow || labelTip">
    <span>{{ label }}</span>
    <el-tooltip effect="dark" :content="isAskShow" placement="top">
      <span v-if="isAskShow" class="askShow" @click="onAskClick">?</span>
    </el-tooltip>
    <span
      v-if="labelTip"
      class="label-tips-msg"
      :class="isAskShow && 'isAskShow'"
      v-text="labelTip"></span>
  </div>
</template>

<script>
  export default {
    name: 'HelpIcon',
    props: {
      // 是否显示问号
      isAskShow: [String, Boolean],
      // 标签提示
      labelTip: String,
      // 标签文本
      label: String,
    },
    methods: {
      /**
       * 点击问号图标
       */
      onAskClick() {
        this.$emit('askClick');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '~@/components/ykc-ui/styles/var.scss';
  .askShow {
    display: inline-block;
    box-sizing: border-box;
    //position: absolute;
    top: 0;
    left: inherit;
    right: -16px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    color: $_FFFFFF;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    background: $theme_primary;
    margin-left: 4px;
  }
  .help-with-icon {
    display: inline-flex;
    position: relative;
    align-items: center;
  }
  .label-tips-msg {
    color: $theme_primary;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: inherit;
    width: 350px;
    margin-left: 10px;
  }
  .label-tips-msg.isAskShow {
    margin-left: 30px;
  }
</style>
